import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import ComponentContainer from './ComponentContainer'
import NewHome from './consumer/NewHome'

function NewComponentPageTemplate({
  data: { datoCmsNewHomePage, datoCmsSite },
  pageContext: { menu, footer, cookiePrompt, consumer }
}) {
  menu.hiddenSub = true
  return (
    <TailwindLayout
      menu={menu}
      footer={footer}
      cookiePrompt={cookiePrompt}
      consumer={consumer}
      page={datoCmsNewHomePage}
    >
      <HelmetDatoCms
        seo={datoCmsNewHomePage.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <NewHome content={datoCmsNewHomePage} />
    </TailwindLayout>
  )
}

export default NewComponentPageTemplate

export const query = graphql`
  query ($pageId: String!, $locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsNewHomePage(originalId: { eq: $pageId }, locale: { eq: $locale }) {
      seoMetaTags {
        tags
      }
      twoItemBlock {
        items {
          topTitle
          title
          description
          icons {
            image {
              alt
              url
              gatsbyImageData
              filename
            }
            title
            link
            linkText
          }
          backgroundImage {
            url
            alt
          }
          cta {
            href
            text
          }
        }
      }
      banner {
        style
        title
        content
        ctas {
          title
          href
          text
        }
      }
    }
  }
`
