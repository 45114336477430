import React, { useState, useEffect } from 'react'
import AppleDevices from '../../components/Consumer/AppleDevices'

const NewHome = (content) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  console.log(content)

  const personal = content.content.twoItemBlock[0].items[0]
  const enterprise = content.content.twoItemBlock[0].items[1]
  const banner = content.content.banner[0]

  const personalStyle = {
    backgroundColor: '#f7f6f6',
    backgroundImage: `url(${personal.backgroundImage.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',
    backgroundPosition: 'left bottom',
    backgroundSize: '50%',
    padding: '100px 50px',
    height: windowWidth < 1010 ? '850px' : '700px'
  }

  const enterpriseStyle = {
    backgroundColor: '#e6f3ea',
    backgroundImage: `url(${enterprise.backgroundImage.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'local',
    backgroundPosition: 'right bottom',
    padding: '100px 50px',
    backgroundSize: '67%',
    height: windowWidth < 1010 ? '850px' : '700px'
  }

  const contactStyle = {
    backgroundColor: '#329fc0',
    padding: windowWidth < 768 ? '50px 30px' : '50px 150px'
  }
  const devicesStyle = {
    display: 'inline-block !important',
    width: '100%'
  }
  const twoBlocksStyle = {
    display: 'inline-block !important'
  }
  const boldStyle = {
    fontWeight: 'bold',
    marginBottom: '10px'
  }
  const buttonPadding = {
    padding: windowWidth < 768 ? '20px 40px' : '20px 40px'
  }

  return (
    <div>
      <div className="flex flex-col md:flex-row" style={twoBlocksStyle}>
        <div className="personal" style={personalStyle}>
          <h4>{personal.topTitle}</h4>
          <h2>{personal.title}</h2>
          <p>{personal.description}</p>
          <div style={devicesStyle}>
            <AppleDevices
              devices={personal.icons.map((item) => {
                return {
                  title: item.linkText,
                  icon: item.image,
                  link: item.link
                }
              })}
            />
          </div>
          {/* <div style={buttonBackground}> */}
          <a href={personal.cta[0].href}>
            <button>{personal.cta[0].text}</button>
          </a>
          {/* </div> */}
        </div>
        <div className="enterprise" style={enterpriseStyle}>
          <h4>{enterprise.topTitle}</h4>
          <h2>{enterprise.title}</h2>
          <p>{enterprise.description}</p>
          <div style={devicesStyle}>
            <AppleDevices
              devices={enterprise.icons.map((item) => {
                return {
                  title: item.linkText,
                  icon: item.image,
                  link: item.link
                }
              })}
            />
          </div>
          {/* <div style={buttonBackground}> */}
          <a href={enterprise.cta[0].href}>
            <button>{enterprise.cta[0].text}</button>
          </a>
          {/* </div> */}
        </div>
      </div>
      <div className="contact" style={contactStyle}>
        <p style={boldStyle}>{banner.title}</p>
        <p>{banner.content}</p>
        <a href={banner.ctas[0].href}>
          <button style={buttonPadding}>{banner.ctas[0].text}</button>
        </a>
      </div>
    </div>
  )
}

export default NewHome
